import * as React from "react";
import { graphql } from "gatsby";
import { Constants } from "../@types/Constants";
import { ISupportProps } from "../pageHelpers/Support/SupportProps";
import IParagraph from "../@types/IParagraph";
import Layout from "../components/global/Layout/Layout";
import { getParagraph } from "../utils/paragraphHelpers";
import ParagraphLayout from "../components/common/ParagraphLayout/ParagraphLayout";
import SEO from "../components/global/SEO/SEO";
import { Storage } from "../utils/storage";

const Support: React.FC<ISupportProps> = (props) => {
  const _page = props.data.page;
  const [activeSectionQuestion, setActiveSectionQuestion] = React.useState({
    section: null,
    question: null,
  });

  const handleSupportTileClick = (section: any, question: any) => {
    setActiveSectionQuestion({
      section: section,
      question: question,
    });
  };

  const handleAfterTileQuestionShown = () => {
    setActiveSectionQuestion({
      section: null,
      question: null,
    });
  };

  const _paragraphs = _page.relationships.paragraphs.map((paragraph) => {
    return getParagraph(paragraph, {
      activeSectionKey: activeSectionQuestion.section,
      activeQuestionKey: activeSectionQuestion.question,
      onTileClick: handleSupportTileClick,
      afterTileClicked: handleAfterTileQuestionShown,
    });
  });
  const _pageBanner = _paragraphs.filter((p: IParagraph) => {
    return p
      ? p.props.paragraphtype === Constants.PARAGRAPH_PAGE_BANNER
      : false;
  });

  React.useEffect(() => {
    Storage.ClearLocalStorageValue(Constants.BRAND_BIAS_KEY);
  }, []);

  return (
    <Layout>
      <SEO title={_page?.title} description={_page?.field_meta_description} />
      {_pageBanner}
      <ParagraphLayout {..._paragraphs} />
    </Layout>
  );
};
export const pageQuery = graphql`
  query SupportQuery {
    page: nodePage(path: { alias: { eq: "/v1/support" } }) {
      id
      title
      field_meta_description
      relationships {
        paragraphs: field_sections {
          type: __typename
          ...ParagraphPageBanner
          ...ParagraphPageIntro
          ...ParagraphSupportTiles
          ...ParagraphFrequentlyAskedQuestions
        }
      }
    }
  }
`;
export default Support;
